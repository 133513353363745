import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import Close from "./close";

const SuccessModal = ({ success }) => {
  const intl = useIntl();
  const [isActive, setIsActive] = useState(false);

  const close = () => {
    setIsActive(false);
  };

  useEffect(() => {
    setIsActive(!!success);
  }, [success]);

  return (
    <div className={`modal modal-success${isActive ? " active" : ""}`}>
      <div className="modal-content">
        <div className="container">
          <div className="row justify-content-center h-100">
            <div className="col-md-10 col-lg-8 col-xl-6">
              <div className="modal-success-inner">
                <button
                  className="modal-close"
                  onClick={close}
                  aria-label={intl.formatMessage({ id: "close" })}
                >
                  <Close />
                </button>
                <div className="d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="80"
                    height="80"
                    fill="none"
                    viewBox="0 0 80 80"
                  >
                    <circle
                      cx="40"
                      cy="40"
                      r="34"
                      stroke="#F9B000"
                      strokeWidth="4"
                    ></circle>
                    <path
                      stroke="#F9B000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="4"
                      d="M26 41l10.923 11L54 32"
                    ></path>
                  </svg>
                  <h3 className="ml-6">{success}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className="modal-backdrop"
        aria-label={intl.formatMessage({ id: "close" })}
        onClick={close}
      ></button>
    </div>
  );
};

export default SuccessModal;
