import React, { useState, useEffect, useRef } from "react";

const SubmitButton = ({ children, isLoading }) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current && ref.current.getBoundingClientRect().width) {
      setWidth(ref.current.getBoundingClientRect().width);
    }
    if (ref.current && ref.current.getBoundingClientRect().height) {
      setHeight(ref.current.getBoundingClientRect().height);
    }
  }, [children]);

  return (
    <button
      type="submit"
      ref={ref}
      style={
        width && height
          ? {
              width: `${width}px`,
              height: `${height}px`,
            }
          : {}
      }
    >
      {isLoading ? (
        <svg
          className="button-loader"
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          fill="none"
          viewBox="0 0 28 28"
        >
          <path
            stroke="#fff"
            strokeWidth="3"
            d="M26 14a12 12 0 10-8.538 11.49"
          ></path>
        </svg>
      ) : (
        children
      )}
    </button>
  );
};

export default SubmitButton;
