import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { graphql, Link } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React, { Fragment } from "react";

import ContactForm from "../components/contact-form";
import Layout from "../components/layout";
import Seo from "../components/seo";
import LazyLoad from "react-lazyload";
import ServicesMap from "../components/services-map";
import { FormattedMessage } from "react-intl";

const ContactTemplate = ({ data, pageContext: context }) => {
  const { title, main_title, introduction } = data.contentfulPage;

  const { airport, station, tourist_office } = data.contentfulHotel;

  return (
    <Layout>
      <Seo title={title} description={main_title} />
      <section className="section section-gradient">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 justify-content-center">
              <div className="col-lg-10 mx-auto">
                {main_title ? (
                  <>
                    <p
                      className={`paragraph font-weight-bold text-center ${
                        data.contentfulHotel.spirit.slug === "travel"
                          ? "text-orange"
                          : "text-yellow"
                      }`}
                    >
                      {title}
                    </p>
                    <h1 className="text-center">{main_title}</h1>
                  </>
                ) : (
                  <h1 className="text-center">{title}</h1>
                )}
                {introduction &&
                  renderRichText(introduction, {
                    renderNode: {
                      [BLOCKS.PARAGRAPH]: (node, children) => (
                        <p className="lead text-center">{children}</p>
                      ),
                      [INLINES.HYPERLINK]: (node, children) => {
                        return (
                          <a
                            href={node.data.uri}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {children}
                          </a>
                        );
                      },
                      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
                        return (
                          <Link
                            to={`/${context.locale}/${node.data.target.slug}/`}
                          >
                            {children}
                          </Link>
                        );
                      },
                    },
                  })}
              </div>
              <hr />
              <div className="row justify-content-between">
                <div className="col-lg-4 mb-4">
                  <h3>
                    {`ACE Hôtel${
                      data.contentfulHotel.spirit.slug === "travel"
                        ? " Travel"
                        : ""
                    } ${data.contentfulHotel.title}`}
                  </h3>
                  <p className="small">
                    {data.contentfulHotel.address.split(",").map((line, i) => (
                      <Fragment key={i}>
                        {line}
                        <br />
                      </Fragment>
                    ))}
                  </p>
                  <p className="small">
                    {data.contentfulHotel.phone && (
                      <a
                        className="link link-tel"
                        href={`tel:${data.contentfulHotel.phone.replace(
                          /\s/g,
                          ""
                        )}`}
                        style={{
                          textDecoration: "underline",
                          textUnderlineOffset: "3px",
                        }}
                      >
                        {data.contentfulHotel.phone}
                      </a>
                    )}
                  </p>
                </div>
                <div className="col-lg-7">
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section-white overflow-hidden">
        <div className="search">
          <div className="search-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-8">
                  <h2 className="mb-4 mb-md-0">
                    <FormattedMessage id="map.access" />
                  </h2>
                </div>
              </div>
            </div>
            <LazyLoad>
              <ServicesMap />
            </LazyLoad>
          </div>
        </div>
      </section>
      <section className="section section-white">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 mb-6 mb-xl-0 pr-xl-10">
              <h2>
                <FormattedMessage id="links" />
              </h2>
            </div>
            {airport && (
              <div className="col-md-4 col-xl-3 pr-md-10">
                {renderRichText(airport, {
                  renderNode: {
                    [INLINES.HYPERLINK]: (node, children) => {
                      return (
                        <a
                          href={node.data.uri}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {children}
                        </a>
                      );
                    },
                  },
                })}
              </div>
            )}
            {station && (
              <div className="col-md-4 col-xl-3 mt-4 mt-md-0 pr-md-10">
                {renderRichText(station, {
                  renderNode: {
                    [INLINES.HYPERLINK]: (node, children) => {
                      return (
                        <a
                          href={node.data.uri}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {children}
                        </a>
                      );
                    },
                  },
                })}
              </div>
            )}
            {tourist_office && (
              <div className="col-md-4 col-xl-3 mt-4 mt-md-0 pr-md-10">
                {renderRichText(tourist_office, {
                  renderNode: {
                    [INLINES.HYPERLINK]: (node, children) => {
                      return (
                        <a
                          href={node.data.uri}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {children}
                        </a>
                      );
                    },
                  },
                })}
              </div>
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ContactTemplate;

export const query = graphql`
  query ($id: String!, $slug: String!, $locale: String!, $hotel: String!) {
    allSitePage(filter: { context: { id: { eq: $id } } }) {
      nodes {
        path
        context {
          locale
        }
      }
    }
    allContentfulPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        contentful_id
        title
        slug
        category
      }
    }
    contentfulHotel(slug: { eq: $hotel }, node_locale: { eq: $locale }) {
      title
      email
      address
      lat
      lon
      phone
      booking_id
      place_id
      business_url
      radius
      hero_image {
        file {
          url
        }
      }
      airport {
        raw
      }
      station {
        raw
      }
      tourist_office {
        raw
      }
      slug
      spirit {
        slug
      }
      seo_site_hotel_meta_description
    }
    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      title
      main_title
      introduction {
        raw
        references {
          contentful_id
          slug
        }
      }
      seo_content {
        raw
      }
    }
    allContentfulHotel(
      filter: { slug: { ne: "enseigne" }, node_locale: { eq: $locale } }
    ) {
      nodes {
        email
        title
      }
    }
    allContentfulPointDinteret(filter: { node_locale: { eq: $locale } }) {
      nodes {
        category
        place_id
        title
        url
        vicinity
        lat
        lng
        hotel {
          title
          slug
        }
      }
    }
  }
`;
